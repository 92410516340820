<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center pb-8 border-b border-gray-200">
      <h3 class="text-base md:text-20 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
        Edit resource
      </h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          :to="{ name: routeNames.resources }"
          variant="danger"
          size="standart-sm"
        >
          Cancel
          <i class="icon-cross text-12 ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 overflow-y-auto">
      <Form
        :validation-schema="resourceCreateUpdateSchema"
        autocomplete="off"
        class="w-full px-4"
        @submit="onSubmitForm"
      >
        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Resource name</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2 pr-0 md:pr-8">
              <AppInput
                id="name"
                v-model="resourceModel.name"
                type="text"
                name="name"
                label="Name"
                placeholder="Name"
                autofocus
                required
              />
            </div>
          </div>
        </div>

        <div class="w-full">
          <div class="w-full flex flex-wrap">
            <div class="w-full md:w-1/2 pr-0 md:pr-8">
              <AppInput
                id="domain"
                v-model="resourceModel.domain"
                type="text"
                name="domain"
                label="Domain"
                placeholder="https://"
                required
              />
            </div>

            <div class="w-full md:w-1/2 pl-0 md:pl-8">
              <AppInput
                id="titleField"
                v-model="resourceModel.titleField"
                type="text"
                name="titleField"
                label="Title field"
                placeholder="title"
                required
              />
            </div>

            <div class="w-full md:w-1/2 pr-0 md:pr-8">
              <AppInput
                id="thumbnailUrlField"
                v-model="resourceModel.thumbnailUrlField"
                :disabled="thumbnailUrlFromContent"
                type="text"
                name="thumbnailUrlField"
                label="Thumbnail URL field"
                placeholder="https://"
                required
              />
            </div>

            <div class="w-full md:w-1/2 pl-0 md:pl-8 flex items-center mb-30 md:mb-0">
              <p class="text-blueGray-500 pr-8">or</p>
              <AppCheckbox
                id="thumbnailUrlFromContent"
                v-model="thumbnailUrlFromContent"
                name="thumbnailUrlFromContent"
                label="Get image from content field"
                class="mt-8"
                @update:modelValue="onThumbnailUrlFromContentChange"
              />
            </div>

            <div class="w-full md:w-1/2 pr-0 md:pr-2">
              <AppInput
                id="contentField"
                v-model="resourceModel.contentField"
                type="text"
                name="contentField"
                label="Content field"
                placeholder="content"
                required
              />
            </div>

            <div class="w-full md:w-1/2 pl-0 md:pl-8">
              <AppInput
                id="guidField"
                v-model="resourceModel.guidField"
                type="text"
                name="guidField"
                label="GUID field"
                placeholder="https://"
                required
              />
            </div>
          </div>
        </div>

        <div class="w-full flex justify-center pt-24 pb-24 md:pb-40 md:pt-40">
          <div class="w-full md:w-1/2">
            <AppButton
              :loading="loading"
              variant="primary"
              type="submit"
              class="w-full"
            >
              Submit
            </AppButton>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Form } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'

import { notification } from '@/core/helpers'
import { routeNames } from '@/router'
import { resourceCreateUpdateSchema } from '@/core/schemas'
import { useResources } from '@/core/compositions'
import { RSS_IMAGE_RESOURCE_TYPE } from '@/core/enums'

import AppButton from '@/components/stateless/AppButton.vue'
import AppInput from '@/components/stateless/AppInput.vue'
import AppCheckbox from '@/components/stateless/AppCheckbox.vue'

export default defineComponent({
  name: 'EditResource',

  components: {
    Form,
    AppButton,
    AppInput,
    AppCheckbox
  },

  setup () {
    const router = useRouter()
    const route = useRoute()

    const { resourceModel, getResource, editResource } = useResources()

    const loading = ref(true)
    const thumbnailUrlFromContent = ref(false)

    const resourceId = computed(() => {
      return route.params?.id
    })

    onMounted(() => {
      getInitialData()
    })

    const getInitialData = async () => {
      loading.value = true

      try {
        await getResource(resourceId.value)

        if (resourceModel.thumbnailUrlField === RSS_IMAGE_RESOURCE_TYPE.FROM_CONTENT) {
          thumbnailUrlFromContent.value = true
        }
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const onSubmitForm = async () => {
      loading.value = true

      try {
        await editResource(resourceId.value)

        notification({ type: 'success', title: 'Success!', message: 'Resource successfully updated!' })

        router.push({ name: routeNames.resources })
      } catch (err) {
        console.log(err)
        notification({ title: 'Resource updating error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    const onThumbnailUrlFromContentChange = () => {
      if (thumbnailUrlFromContent.value) {
        resourceModel.thumbnailUrlField = RSS_IMAGE_RESOURCE_TYPE.FROM_CONTENT
      } else {
        resourceModel.thumbnailUrlField = null
      }
    }

    return {
      routeNames,
      resourceCreateUpdateSchema,
      loading,
      onSubmitForm,
      resourceModel,
      thumbnailUrlFromContent,
      onThumbnailUrlFromContentChange
    }
  }
})
</script>
